<template>
  <div class="" style="position: relative">
    <!-- <div
      v-if="startLoading"
      style="
        position: absolute;
        background-color: rgba(211, 211, 211, 0.2);
        height: 100%;
        width: 100%;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <span
        style="
          background-color: #c0dfff;
          color: #409eff;
          padding: 7px 30px;
          border-radius: 3px;
        "
        >{{ $t("loading...") }}</span
      >
    </div> -->

    
        

          <!-- <div class="form-group row">
              <div class="col-sm-12 mb-3">
                  <h5 class="label-form mb-2 text-14">
                      USDT {{ $t("balance") }}
                  </h5>

                  <input type="text" class="form-control label-input" v-model="member.point1" readonly />
              </div>
          </div>

          <div class="form-group row">
              <div class="col-sm-12 mb-3">
                  <h5 class="label-form mb-2 text-14">
                      {{ $t("withdrawAmount") }}
                  </h5>

                  <input type="text" class="form-control label-input" v-model="amount" @change="updateAmount" @input="updateAmount" min="100" step="0.01" />
                  <p class="text-white text-10">{{ $t("withdraw_fee_remark_0") }} {{ min_withdraw_amount }} USDT.</p>
              </div>
          </div> -->
    <b-tabs id="withdraw-tabs" pills fill>
      <b-tab class="mt-3 mb-1" @click="selectIndex('COIN')" active >
        <template #title>
          <div>
            <span class="font-weight-bold">
              {{ $t('to_wallet') }}
            </span>
          </div>
        </template>
        <b-form @submit.prevent="doWithdraw"> 
          <div class="sell-etnex-container black-dark-blue-card mb-3">
           <div class="sell-card">
              <div class="">
                <div class="mb-1 text-12 text-white">
                    {{ $t("wallet_address") + " (BEP20)" }}
                </div>
                <div class="custom-input-group black-dark-blue-input mb-3">
                  <input class="form-control text-white" v-model="member.username" required disabled/>
                </div>
              </div>
              <div class="">
                <div class="mb-1 text-12 text-white">
                    {{ $t("total_usdt") }}
                </div>
                <div class="custom-input-group black-dark-blue-input mb-3">
                  <input class="form-control text-white" v-model="member.point1" readonly disabled/>
                </div>
              </div>
              <div class="">
                <div class="mb-1 text-12 text-white">
                  {{ $t("withdrawAmount") + " (USDT)"}}
                </div>
                <div class="custom-input-group black-dark-blue-input mb-3">
                  <input 
                    type="number" 
                    class="custom-input form-control text-white" 
                    :placeholder="$t('withdraw_fee_remark_0') + '' + min_withdraw_amount + ' USDT'"
                    v-model="amount" 
                    @change="updateAmount" 
                    @input="updateAmount" 
                    :min="min_withdraw_amount"
                    step="0.01"
                  />
                </div>
              </div>
              <div class="">
                <div class="mb-1 text-12 text-white">
                  {{ $t("sec_password") }}
                </div>
                <div class="row">
                <div class="input-group col-ms-12 ml-1">
                  <input v-for="(digit, index) in digits" :key="index" type="password"
                    class="form-control label-input "
                    @input="updateDigit1(index, $event.target.value)" min="0" max="9" pattern="[0-9]"
                    required maxlength="1" ref="inputFields1" v-model="digits[index]" />
                  <!-- <input 
                    type="password" 
                    class="custom-input form-control text-white" 
                    v-model="sec_pwd" 
                  /> -->
                </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="mt-3 mb-4 btn-curved-wallet"
            variant="primary"
            block
            :disabled="isLoading"
            >{{ isLoading ? $t("loading...") : $t("withdraw") }}</button
          >
          <div class="info-box black-dark-blue-card mt-2">
            <div class="info-line">
              <span class="info-title">{{feeInPercentage ?$t("fees") + " ( " + fee + $t("in") + " )" : $t("fees") + " (USDT)"}}</span>
              <span class="info-content">{{ this.totalFee }} USDT</span>   
            </div> 
            <div class="info-line"> 
              <span class="info-title">{{ $t("usdt_get_amount") }}</span>
              <span class="info-price">{{ this.u_amount }} USDT</span>                
            </div>
          </div>                
          <div>
            <p class="text-white text-10 mt-2 ml-2">{{ $t("withdraw_fee_remark_1") }} {{ fee }}%.</p>
          </div>
        </b-form>
      </b-tab>

      <b-tab class="mt-3 mb-1" @click="selectIndex('CASH')">
        <template #title>
          <div id="tab-title">
            <span class="font-weight-bold">
              {{ $t('to_card') }}
            </span>
          </div>
        </template>
        <b-form @submit.prevent="doWithdrawCash"> 
          <div class="sell-etnex-container black-dark-blue-card mb-3">
            <div class="sell-card">
              <div class="">
                <div class="mb-1 text-12 text-white">
                    {{ $t("card_no") }}
                </div>
                <div class="custom-input-group black-dark-blue-input mb-3">
                  <!-- <input class="form-control text-white" type="number"/> -->
                  <input
                    type="text"
                    class="credit-card text-white form-control"
                    maxlength="19"
                    pattern="[0-9-]*"
                    inputmode="numeric"
                    v-model="member.card"
                    @input="formatCreditCard"
                    readonly
                  />
                </div>
              </div>
              <div class="">
                <div class="mb-1 text-12 text-white">
                    {{ $t("total_usdt") }}
                </div>
                <div class="custom-input-group black-dark-blue-input mb-3">
                  <input class="form-control text-white" v-model="member.point1" readonly disabled/>
                </div>
              </div>
              <div class="">
                <div class="mb-1 text-12 text-white">
                  {{ $t("withdrawAmount") + " (USDT)"}}
                </div>
                <div class="custom-input-group black-dark-blue-input mb-3">
                  <input 
                    type="number" 
                    class="custom-input form-control text-white" 
                    :placeholder="$t('withdraw_fee_remark_0') + '' + '120 USDT'"
                    v-model="amount" 
                    @change="updateAmount2" 
                    @input="updateAmount2" 
                    :min="120"
                    step="0.01"
                  />
                </div>
              </div>
              <div class="">
                <div class="mb-1 text-12 text-white">
                  {{ $t("sec_password") }}
                </div>
                  <div class="row">
                <div class="input-group col-ms-12 ml-1">
                  <input v-for="(digit, index) in digits" :key="index" type="password"
                    class="form-control label-input"
                    @input="updateDigit2(index, $event.target.value)" min="0" max="9" pattern="[0-9]"
                    required maxlength="1" ref="inputFields2" v-model="digits[index]"/>
                </div>
                  </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="mt-3 mb-4 btn-curved-wallet"
            variant="primary"
            block
            :disabled="isLoading"
            >{{ isLoading ? $t("loading...") : $t("withdraw") }}</button
          >
          <div class="info-box black-dark-blue-card mt-2">
            <div class="info-line">
              <span class="info-title">{{feeInPercentage ?$t("withdrawal_fees") +   fee + "%" : $t("fees") }}</span>
              <span class="info-content">{{ this.totalFee2 }} USDT</span>   
            </div>
            <div class="info-line">
              <span class="info-title">{{feeInPercentage ?$t("processing_fees") +  fee +"%"  : $t("fees")}}</span>
              <span class="info-content">{{ this.totalFee3 }} USDT</span>   
            </div>  
            <div class="info-line"> 
              <span class="info-title">{{ $t("usdt_get_amount") }}</span>
              <span class="info-price">{{ this.u_amount2 }} USDT</span>                
            </div>
          </div>                
          <div>
            <p class="text-white text-10 mt-2 ml-2">{{ $t("withdraw_fee_remark_4") + fee + "%" + $t("withdraw_fee_remark_5") + fee + "%."}}</p>
          </div>
        </b-form>
      </b-tab>
    </b-tabs>

          <!-- <div class="info-box black-dark-blue-card mt-2">
            <div class="info-line">
              <span class="info-title">{{feeInPercentage ?$t("fees") + " ( " + fee + $t("in") + " )" : $t("fees") + " (USDT)"}}</span>
              <span class="info-content">{{ this.totalFee }} USDT</span>   
            </div> 
            <div class="info-line"> 
              <span class="info-title">{{ $t("usdt_get_amount") }}</span>
              <span class="info-price">{{ this.u_amount }} USDT</span>                
            </div>
          </div>                
          <div>
            <p class="text-white text-10 mt-2 ml-2">{{ $t("withdraw_fee_remark_1") }} {{ fee*2 }}%.</p>
          </div> -->

          <h2 class="text_h2_etnex text text-light-green mt-4 pt-10">{{ $t("withdrawal_history") }}</h2>
          <div class="scrollable-container">
            <div v-if="dataList.length > 0">
              <div class="notification-card" v-for="item in dataList" :key="item.id">
                <div class="icon">
                  <img src="../../../assets/images/etnex_wallet/withdraw_icon.png" alt="icon" class="icon">
                </div>
                <div class="content">
                  <span class="title text">{{ $t("withdraw") }} {{ $t(site[item.status]) }}</span>
                  <span class="date text"></span>
                </div>
                <div class="text-right">
                  <span class="con_address text">{{ getDate(item.created_at) }}</span>
                  <span class="text-grey"> {{ " | "}}</span>
                  <span class="con_address text">{{ getTime(item.created_at) }}</span>
                  <div class="text-light-green font-weight-bold">{{ item.amount }} USDT</div>                
                </div>
              </div>
            </div>
          </div>
            <!-- <h3 class="no-data-text text">
              {{ $t("no_data") }}
            </h3> -->

          
          <!-- <div class="">
              <div class="d-flex align-item-center justify-content-between ">
                  <div>
                    <span class="label-form text-14">
                      {{ $t("usdt_get_amount") }}
                    </span>
                  </div>
                  <div class="text-right">
                    <span class="mb-0 text-white text-14">  {{u_amount }} USDT </span>
                  </div>
              </div>
          </div>
          </div> -->

           <!-- <div class="form-group row">
              <div class="col-sm-12 mb-3">
                  <h5 class="label-form mb-2 text-14">
                      {{ feeInPercentage ?$t("fees") + " ( " + fee + $t("in") + " )" : $t("fees") + " (USDT)"}}
                  </h5>
                  <input type="text" class="form-control label-input" v-model="totalFee" readonly />
                  <p class="text-white text-10">{{ $t("withdraw_fee_remark_1") }} {{ min_withdraw_amount_in_fee }}  {{ $t("withdraw_fee_remark_2") }} {{ fee }} {{ $t("withdraw_fee_remark_3") }}</p>
              </div>
          </div>

          <div class="form-group row">
              <div class="col-sm-12 mb-3">
                  <h5 class="label-form mb-2 text-14">
                      {{ $t("usdt_get_amount") }}
                  </h5>

                  <input type="text" class="form-control label-input" v-model="u_amount" readonly />
              </div>
          </div>

          <div class="form-group row">
              <div class="col-sm-12 mb-3">
                  <h5 class="label-form mb-2 text-14">
                      {{ $t("sec_password") }}
                  </h5>

                  <input type="text" class="form-control label-input" v-model="sec_pwd" required />
              </div>
          </div> -->

          <!--<h5 style="color:red">{{$t("remark")}}</h5>
            <h6 style="color:red">{{ $t("withdraw_rules") }}</h6>-->

          <!-- <b-button
            type="submit"
            class="mt-5 mb-5 btn-curved"
            variant="primary"
            block
            :disabled="isLoading"
            >{{ isLoading ? $t("loading...") : $t("withdraw") }}</b-button
          > -->
        
   

    <Dialog ref="msg"></Dialog>
  </div>
</template>

<script>
import {
  withdraw,
  withdrawCash,
  getDownloadLink,
  getMemberInfo,
  getWithdrawRecord,
} from "../../../system/api/api";
import { handleError } from "../../../system/handleRes";
import { mapGetters } from "vuex";
import Dialog from "../../../components/dialog.vue";
export default {
  props: [
    "fee",
    "min_withdraw_amount",
    "min_withdraw_amount_in_fee",
    "preset_address",
    "totalAmount",
    "canWithdraw",
    "startLoading",
  ],
  computed: {
    ...mapGetters(["lang"]),
  },
  components: {
    Dialog,
  },
 
  data() {
    return {
      address: "",
      digits: new Array(4).fill(''),
      confirm_amount: 0,
      amount: null,
      u_amount: 0,
      u_amount2: 0,
      sec_pwd: "",
      selected: "COIN",
      walletSelected: "point1",
      dfi_price: "",
      isLoading: false,
      totalFee: 0,
      totalFee2: 0,
      totalFee3: 0,
      dataList: [],
      bank_number: "",
      currentPage: 1,
      lastPage: 1,
      site: ["pending", "approved", "success", "rejected"],
      member: {
        point1: 0,
      },
      walletOptions: [
        {
          value: "point1",
          text: this.$t("point1"),
        },
      ],
      feeInPercentage: true,
    };
  },
  methods: {
    updateDigit1(index, value) {
      this.digits[index] = value;
      this.sec_pwd = this.digits.join('');
      if (index < this.digits.length - 1 && this.$refs.inputFields1[index + 1] ) {
          this.$refs.inputFields1[index + 1].focus();
      }
    },
    updateDigit2(index, value) {
      this.digits[index] = value;
      this.sec_pwd = this.digits.join('');
      if (index < this.digits.length - 1 && this.$refs.inputFields2[index + 1] ) {
          this.$refs.inputFields2[index + 1].focus();
      }
    },
    getDate(dateTime) {
      const date = new Date(dateTime);
      const datePart = date.toLocaleDateString('en-US');
      return datePart;
    },
    getTime(dateTime) {
      const date = new Date(dateTime);
      const timePart = date.toLocaleTimeString('en-US', { hour12: false });
      return timePart;
    },
    goDetails(item) {
      this.$router.push({
        name: "recordDetails",
        params: {
          item: item,
          type: 'withdraw'
        },
      });
    },
    selectIndex(i) {
      this.selected = i;
      this.sec_pwd = "";
      this.dataList = [];
      this.currentPage = 1;
      this.lastPage = 1;

      this.getRecord();
    },
    getRecord() {
      var result = getWithdrawRecord(this.selected, this.currentPage);
      var self = this;
      self.isLoading = true;

      result
        .then(function (value) {
          var dataList = value.data.data.data;
          self.currentPage += 1;
          self.lastPage = value.data.data.last_page;
          for (let i = 0; i < dataList.length; i++) {
            self.dataList.push(dataList[i]);
          }
          self.isLoading = false;
        })
        .catch(function (error) {
          self.isLoading = false;
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },
    updateAmount() {
      this.totalFee = ((this.amount * this.fee) / 100).toFixed(3);
    /* if (parseFloat(this.totalFee) > parseFloat(this.min_withdraw_amount_in_fee)) */
      { 
      
        console.log(this.u_amount);
        this.u_amount = (parseFloat(this.amount) * (100 - parseFloat(this.fee))) / 100;
        if (this.u_amount < 0 || this.amount =="") {
          this.u_amount = 0;
        }
        this.totalFee = (parseFloat(this.amount) * parseFloat(this.fee)) / 100;
        if (this.totalFee < 0 || this.amount =="") {
          this.totalFee = 0;
        }
        this.feeInPercentage = true;
      } 
    
    /* else {
      
      console.log(this.u_amount);
      this.u_amount = parseFloat(this.amount) - parseFloat(this.min_withdraw_amount_in_fee);
      if (this.u_amount < 0 || this.amount =="") {
        this.u_amount = 0;
      }
      this.totalFee = parseFloat(this.min_withdraw_amount_in_fee);
      this.feeInPercentage = false;
    } */

      
    },
    updateAmount2() {
      const firstReduction = (parseFloat(this.amount) * this.fee) / 100;
      const reducedAmount1 = this.amount - firstReduction;
      const secondReduction = (parseFloat(reducedAmount1) * this.fee) / 100;
      const finalAmount = reducedAmount1 - secondReduction;

      this.u_amount2 = finalAmount.toFixed(2);
        if (this.u_amount2 < 0 || this.amount =="") {
          this.u_amount2 = 0;
        }
        
      this.totalFee2 = firstReduction;
        if (this.totalFee2 < 0 || this.amount == "") {
          this.totalFee2 = 0;
      }
      this.totalFee3 = secondReduction.toFixed(2);
        if (this.totalFee3 < 0 || this.amount == "") {
          this.totalFee3 = 0;
      }
      this.feeInPercentage = true;     
    },

    doWithdraw() {
      console.log(this.preset_address);
      let formData = new FormData();
      formData.append("amount", this.amount);
      formData.append("address", this.member.username);
      formData.append("wallet_type", this.walletSelected);
      formData.append("sec_password", this.sec_pwd);
      console.log(formData);

      var self = this;
      self.isLoading = true;
      var result = withdraw(formData);

      result
        .then(function (value) {
          if (value.data.code == 0) {
            self.$root.makeToast("success", self.$t(value.data.message));
            setTimeout(() => {
              self.$router.push("/web/mywallet");
            }, 2000);
          } else {
            self.$refs.msg.makeToast("danger", self.$t(value.data.message));
          }
          self.sec_pwd = "";
          self.isLoading = false;
        })
        .catch(function (error) {
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          self.isLoading = false;
          self.sec_pwd = "";
        });
    },

    doWithdrawCash() {
      // console.log(this.preset_address);
      let formData = new FormData();
      formData.append("sec_password", this.sec_pwd);
      formData.append("amount", this.amount);
      formData.append("bank_number", this.bank_number);
      // formData.append("address", this.member.username);
      formData.append("wallet_type", this.walletSelected);
      console.log(formData);

      var self = this;
      self.isLoading = true;
      var result = withdrawCash(formData);

      result
        .then(function (value) {
          if (value.data.code == 0) {
            self.$root.makeToast("success", self.$t(value.data.message));
            setTimeout(() => {
              self.$router.push("/web/mywallet");
            }, 2000);
          } else {
            self.$refs.msg.makeToast("danger", self.$t(value.data.message));
          }
          self.sec_pwd = "";
          self.isLoading = false;
        })
        .catch(function (error) {
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          self.isLoading = false;
          self.sec_pwd = "";
        });
    },

    globalInfo() {
      var result = getDownloadLink();
      var self = this;
      result
        .then(function (value) {
          self.dfi_price = value.data.data.system.DFI_PRICE;
        })
        .catch(function () {});
    },
    memberInfo() {
      var result = getMemberInfo();
      var self = this;
      result
        .then(function (value) {
          self.member = value.data.data;
        })
        .catch(function () {});
    },
    formatCreditCard() {
      let numberWithoutDashes = this.bank_number.replace(/[^\d]/g, '');
      let formattedNumber = numberWithoutDashes.replace(/(.{4})/g, '$1-');
      formattedNumber = formattedNumber.replace(/-$/, '');
      this.bank_number = formattedNumber;
    //   let numberWithoutDashes = this.bank_number.replace(/[^0-9]/g, '');
    //   let formattedNumber = numberWithoutDashes.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, "$1-");
    //  this.bank_number = formattedNumber;
    }
  },
  created() {
    this.globalInfo();
    this.memberInfo();
    this.getRecord();
  },
};
</script>

<style>
.withdraw-container {
  border: 1px solid #0BDDCC !important;
  background: transparent;
}

.info-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0; 
  }

.info-box {
    background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%));
    border-radius: 8px;
    color: white;
    border-radius: 8px;
    padding: 16px 20px;
    display: flex;
    font-family: Poppins;
    flex-direction: column;
    justify-content: space-between;
  }

.info-title{
    color: var(--Secondary, #7E8088);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

.info-icon{
    cursor: pointer;
    color: #fff;
    font-size: 18px;
  }

.sell-etnex-container {
    background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%));
    max-width: 100%;
    margin: auto;
    color: white;
    padding: 7px;
    font-family: 'Poppins', sans-serif;
    border-radius: 8px;
  }

.sell-card {
  border-radius: 12px;
  color: #fff;
  padding: 8px;
  font-family: 'Poppins', sans-serif;
}

.custom-input-group {
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden; 
  background-color: transparent;
  color: #fff;
}

.custom-input {
  flex-grow: 1;
  border: none;
  padding: 10px;
  color: white;
  background: transparent;
}

.scrollable-container {
  max-height: 240px; 
  overflow-y: auto; 
}
.notification-card {
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #152331 0%, #000000 100%);
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 7px;
}

.icon {
  margin-right: 5px;
  flex-shrink: 0;
}

.icon img {
  background-color: transparent;
  border-radius: 5px;
  padding: 8px;
}

  .content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
  }

  .upper-row, .lower-row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
  }

  .date_amount {
    align-content: flex-end;
  }

  .title {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
  }

  .date {
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 1px;
  }

  .no-data-text{
    font-weight: 400;
    font-size: 16px;
    flex-grow: 1;
    text-align: center;
    margin-top: 40px;
    color: var(--Secondary, #7E8088) !important;
  }
  .title.box {
    background-origin: red;
  }
</style>